


































import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class ManageCommunityBoard extends Vue {
  toPage(route: string) {
    if (this.$route.path !== route) {
      this.$router.push(route)
    }
  }
  get isPostedJobs() {
    return this.$route.path === '/recruitment/posted-jobs'
  }
  get isAllPost() {
    return this.$route.path === '/recruitment/posts'
  }
  get isMyApply() {
    return this.$route.path === '/recruitment/my-apply'
  }
}
